<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";

import Manage from "./CellFramework/ConstructionManage.vue";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

export default {
  emits: ["selected-assets", "edit-value", "delete-value"],
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    page: { type: Number },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    perPage: { type: Number, default: 100 },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        maxWidth: 110,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.construction_document_no"),
        field: "construction_document_no",
        suppressSizeToFit: true,
        cellRenderer: (params) => params.value || "-",
      },
      {
        headerName: this.$t("depreciation.construction_name"),
        field: "construction_name",
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("depreciation.construction_budget"),
        field: "construction_budget",
        suppressSizeToFit: true,
        cellClass: "text-end",
      },
      {
        headerName: this.$t("depreciation.construction_create_date"),
        field: "construction_create_date",
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.construction_document_order"),
        field: "construction_document_order",
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("depreciation.construction_invoice_no"),
        field: "construction_invoice_no",
        suppressSizeToFit: true,
        cellRenderer: (params) => params.value || "-",
      },
      {
        headerName: this.$t("depreciation.construction_invoice_date"),
        field: "construction_invoice_date",
        suppressSizeToFit: true,
        cellRenderer: (params) => params.value || "-",
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.construction_vendor"),
        field: "construction_vendor",
        suppressSizeToFit: true,
        cellRenderer: (params) => params.value || "-",
      },
      {
        headerName: this.$t("depreciation.construction_list"),
        field: "construction_list",
        suppressSizeToFit: true,
        cellRenderer: (params) => params.value || "-",
      },
      {
        headerName: this.$t("depreciation.construction_ex_vat"),
        field: "construction_ex_vat",
        suppressSizeToFit: true,
        cellClass: "text-end",
      },
      {
        headerName: this.$t("depreciation.construction_vat"),
        field: "construction_vat",
        suppressSizeToFit: true,
        cellClass: "text-end",
      },
      {
        headerName: this.$t("depreciation.construction_net"),
        field: "construction_net",
        suppressSizeToFit: true,
        cellClass: "text-end",
      },
      {
        headerName: this.$t("depreciation.construction_manage"),
        field: "construction_manage",
        suppressSizeToFit: true,
        cellRenderer: "Manage",
        cellRendererParams: {
          editValue: this.editValue.bind(this),
          deleteValue: this.deleteValue.bind(this),
        },
        hide: this.$route.name === "construction-history",
        cellClass: "justify-center",
      },
    ];
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  computed: {
    rowData() {
      try {
        return this.assetData.map((row, index) => {
          return {
            index: (this.page - 1) * 100 + index + 1,
            construction_document_no: row.construction_no,
            construction_name: row.name,
            construction_budget: toShowAccountingPrice(row.budget),
            construction_create_date: this.momentDay(row.created_at),
            construction_document_order: row.document_no,
            construction_invoice_no: row.invoice_no,
            construction_invoice_date: this.momentDay(row.invoice_date),
            construction_vendor: row.vendor_name,
            construction_list: row.description,
            construction_ex_vat: toShowAccountingPrice(row.amount_ex_vat),
            construction_vat: toShowAccountingPrice(row.vat),
            construction_net: toShowAccountingPrice(row.total_amount),
            construction_manage: row,
          };
        });
      } catch (err) {
        return [];
      }
    },
  },
  components: {
    AgGridVue,
    Manage,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  methods: {
    qrCode(asset) {
      if (asset.qrCode === "-" || !asset.qrCode) {
        return this.$t("home.not_reg");
      } else {
        return asset.qrCode;
      }
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : "-";
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },

    editValue(value) {
      this.$emit("edit-value", value);
    },
    deleteValue(value) {
      this.$emit("delete-value", value);
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
