<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
          type="button"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <!-- Create modal -->
    <b-modal
      class="p-2"
      id="create-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <form @submit.prevent="createConstruction">
        <div>
          <button
            class="d-block close_modal text-light border-none"
            @click="hideConstructionModal"
            type="button"
          >
            <b-icon-x scale="1.5" />
          </button>
        </div>
        <h4 class="text-center mb-4" style="color: #007AFE;">
          {{ $t("depreciation.construction_info") }}
        </h4>
        <div class="create-input">
          <label for="">{{
            $t("depreciation.construction_document_no")
          }}</label>
          <input type="text" v-model="construction.documentNo" />
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_name") }}</label>
          <v-select
            v-if="construction.selectExist"
            class="search-select"
            :options="constructionList"
            label="name"
            :clearable="false"
            :reduce="(option) => option.name"
            v-model="construction.name"
          >
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!construction.name"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
          <input type="text" v-model="construction.name" v-else required />
          <div class="d-flex align-items-center" style="margin-top: 5px;">
            <input
              type="checkbox"
              v-model="construction.selectExist"
              @change="checkConstruction($event)"
            />
            <label class="ms-1">
              {{ $t("depreciation.select_existed_assets") }}
            </label>
          </div>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_budget") }}</label>
          <vue-numeric
            class="numeric-input"
            separator=","
            decimal-separator="."
            :precision="2"
            v-model="construction.budget"
          ></vue-numeric>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_invoice_no") }}</label>
          <input type="text" v-model="construction.invoiceNo" />
        </div>
        <div class="create-input">
          <label for=""
            >{{ $t("depreciation.construction_invoice_date")
            }}<span class="require-red">*</span></label
          >
          <date-picker
            class="h-full"
            locale="en"
            v-model="construction.invoiceDate"
            :masks="masks"
            is-required
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                :value="inputValue"
                class="calendar-input"
                @click="togglePopover()"
                readonly
              />
            </template>
          </date-picker>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_vendor") }}</label>
          <input type="text" v-model="construction.vendor" />
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_list") }}</label>
          <textarea
            name=""
            id=""
            rows="5"
            v-model="construction.list"
          ></textarea>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_ex_vat") }}</label>
          <vue-numeric
            class="numeric-input"
            separator=","
            decimal-separator="."
            :precision="2"
            v-model="construction.excludeVat"
          ></vue-numeric>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_vat") }}</label>
          <div class="row">
            <div class="col-9">
              <vue-numeric
                class="numeric-input"
                separator=","
                decimal-separator="."
                :precision="2"
                v-model="construction.vat"
              ></vue-numeric>
            </div>
            <div class="col-3">
              <select class="col4" v-model="construction.vatType">
                <option value="percent">%</option>
                <option value="value">฿</option>
              </select>
            </div>
          </div>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_net") }}</label>
          <vue-numeric
            class="numeric-input"
            disabled
            separator=","
            decimal-separator="."
            :precision="2"
            :value="createNet"
          ></vue-numeric>
        </div>
        <div class="row justify-content-center gapx-2">
          <button
            class="cancel-button"
            @click="hideConstructionModal"
            type="button"
          >
            {{ $t("btn.canc") }}
          </button>
          <button class="confirm-button">
            {{ $t("btn.save") }}
          </button>
        </div>
      </form>
    </b-modal>

    <!-- Edit modal -->
    <b-modal
      class="p-2"
      id="edit-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <form @submit.prevent="editConstruction">
        <div>
          <button
            class="d-block close_modal text-light border-none"
            @click="hideEditModal"
            type="button"
          >
            <b-icon-x scale="1.5" />
          </button>
        </div>
        <h4 class="text-center mb-4" style="color: #007AFE;">
          {{ $t("depreciation.construction_info") }}
        </h4>
        <div class="create-input">
          <label for="">{{
            $t("depreciation.construction_document_no")
          }}</label>
          <input type="text" v-model="edit.documentNo" />
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_name") }}</label>
          <input type="text" :value="edit.name" disabled />
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_budget") }}</label>
          <vue-numeric
            class="numeric-input"
            separator=","
            decimal-separator="."
            :precision="2"
            v-model="edit.budget"
          ></vue-numeric>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_invoice_no") }}</label>
          <input type="text" v-model="edit.invoiceNo" />
        </div>
        <div class="create-input">
          <label for=""
            >{{ $t("depreciation.construction_invoice_date")
            }}<span class="require-red">*</span></label
          >
          <date-picker
            class="h-full"
            locale="en"
            v-model="edit.invoiceDate"
            :masks="masks"
            is-required
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                :value="inputValue"
                class="calendar-input"
                @click="togglePopover()"
                readonly
              />
            </template>
          </date-picker>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_vendor") }}</label>
          <input type="text" v-model="edit.vendor" />
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_list") }}</label>
          <textarea name="" id="" rows="5" v-model="edit.list"></textarea>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_ex_vat") }}</label>
          <vue-numeric
            class="numeric-input"
            separator=","
            decimal-separator="."
            :precision="2"
            v-model="edit.excludeVat"
          ></vue-numeric>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_vat") }}</label>
          <div class="row">
            <div class="col-9">
              <vue-numeric
                class="numeric-input"
                separator=","
                decimal-separator="."
                :precision="2"
                v-model="edit.vat"
              ></vue-numeric>
            </div>
            <div class="col-3">
              <select class="col4" v-model="edit.vatType">
                <option value="percent">%</option>
                <option value="value">฿</option>
              </select>
            </div>
          </div>
        </div>
        <div class="create-input">
          <label for="">{{ $t("depreciation.construction_net") }}</label>
          <vue-numeric
            class="numeric-input"
            disabled
            separator=","
            decimal-separator="."
            :precision="2"
            :value="editNet"
          ></vue-numeric>
        </div>
        <div class="row justify-content-center gapx-2">
          <button class="cancel-button" @click="hideEditModal" type="button">
            {{ $t("btn.canc") }}
          </button>
          <button class="confirm-button">
            {{ $t("btn.save") }}
          </button>
        </div>
      </form>
    </b-modal>

    <!-- Delete modal -->
    <b-modal
      id="delete-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <img
        class="d-block mx-auto my-3"
        src="../../assets/delete_bin.svg"
        alt=""
      />
      <p class="text-center">{{ $t("dialog.delete_confirm") }}</p>
      <div class="row justify-content-center gapx-2">
        <button
          class="cancel-button"
          @click="$bvModal.hide('delete-modal')"
          type="button"
        >
          {{ $t("btn.canc") }}
        </button>
        <button
          class="confirm-button confirm-button-red"
          @click="deleteConstruction"
        >
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>

    <!-- Convert to asset modal -->
    <b-modal
      class="p-2"
      id="convert-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <form @submit.prevent="createAsset">
        <div>
          <button
            class="d-block close_modal text-light border-none"
            @click="hideConvertModal"
            type="button"
          >
            <b-icon-x scale="1.5" />
          </button>
        </div>
        <h4 class="text-center mb-4" style="color: #007AFE;">
          {{ $t("depreciation.convert_to_asset") }}
        </h4>
        <div class="create-input">
          <label for="">{{ $t("asset.fixedas_num") }}</label>
          <input
            type="text"
            v-model="convert.fixedAssetNo"
            v-if="convert.selectExist"
            required
          />
          <v-select
            v-else
            class="search-select"
            :options="assetList"
            label="id_fixed_asset"
            :clearable="false"
            :reduce="(option) => option.id_fixed_asset"
            v-model="convert.fixedAssetNo"
          >
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!convert.fixedAssetNo"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
          <div class="d-flex align-items-center" style="margin-top: 5px;">
            <input
              type="checkbox"
              v-model="convert.selectExist"
              @change="checkConvert($event)"
            />
            <label class="ms-1">
              {{ $t("registeritem.add_new_asset") }}
            </label>
          </div>
        </div>
        <div class="create-input">
          <label for="">{{ $t("asset.as_name") }}</label>
          <select v-model="convert.asset" required>
            <option
              v-for="item in constructionList"
              :value="item"
              :key="item.name"
              >{{ item.name }}</option
            >
          </select>
        </div>
        <div class="create-input">
          <label for="">{{ $t("asset.purchase_date") }}</label>
          <date-picker
            class="h-full"
            locale="en"
            v-model="convert.purchaseDate"
            :masks="masks"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                :value="inputValue"
                class="calendar-input"
                @click="togglePopover()"
                readonly
              />
            </template>
          </date-picker>
        </div>
        <div class="create-input">
          <label for="">{{ $t("asset.start_date") }}</label>
          <date-picker
            class="h-full"
            locale="en"
            v-model="convert.startDate"
            :masks="masks"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                :value="inputValue"
                class="calendar-input"
                @click="togglePopover()"
                readonly
              />
            </template>
          </date-picker>
        </div>
        <div class="create-input">
          <label for="">{{ $t("asset.purchase_price") }}</label>
          <vue-numeric
            class="numeric-input"
            separator=","
            decimal-separator="."
            :precision="2"
            :value="convert.asset.value"
            disabled
          ></vue-numeric>
        </div>
        <div class="row justify-content-center gapx-2">
          <button class="cancel-button" @click="hideConvertModal" type="button">
            {{ $t("btn.canc") }}
          </button>
          <button class="confirm-button">
            {{ $t("btn.save") }}
          </button>
        </div>
      </form>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center">
            <button
              class="me-3 d-flex my-1 create-button"
              @click="showCreateConstructionModal"
            >
              <b-icon-plus font-scale="1.5" style="margin-right: 5px" />
              <p class="m-0">{{ $t("depreciation.add_construction") }}</p>
            </button>
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
            <button
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="showConvertConstructionModal"
            >
              <b-icon-file-earmark-plus
                font-scale="1.5"
                style="margin-right: 5px"
              />
              <p>{{ $t("depreciation.convert_to_asset") }}</p>
            </button>
          </div>
          <div class="d-flex align-items-center flex-wrap">
            <div
              class="search-box d-flex"
              style="border-radius: 10px; margin-right: 12px;"
            >
              <img src="@/assets/search-white.svg" width="20px" />
              <input
                type="text"
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </div>
            <button
              class="btn-icon d-flex"
              @click="$router.push({ name: 'construction-history' })"
            >
              <b-icon-clock-history font-scale="1.5" />
            </button>
          </div>
        </div>
        <ConstructionTable
          :page="currentPage"
          :perPage="perPage"
          :assetData="itemData"
          @edit-value="showEditModal"
          @delete-value="showDeleteModal"
        ></ConstructionTable>
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadCounstructionData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const DEFAULT_CONSTRUCTION = {
  documentNo: "",
  name: "",
  selectExist: false,
  budget: 0,
  invoiceNo: "",
  invoiceDate: null,
  vendor: "",
  list: "",
  excludeVat: 0,
  vat: 0,
  vatType: "percent",
};
const DEFAULT_EDIT = {
  constructionId: null,
  documentNo: "",
  name: "",
  budget: 0,
  invoiceNo: "",
  invoiceDate: new Date(),
  vendor: "",
  list: "",
  excludeVat: 0,
  vat: 0,
  vatType: "value",
};

const DEFAULT_CONVERT = {
  fixedAssetNo: "",
  selectExist: false,
  asset: {
    name: "",
    value: 0,
  },
  purchaseDate: new Date(),
  startDate: new Date(),
};

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ConstructionTable from "../../components/Table/Depreciation/ConstructionTable.vue";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import baseUrl from "../../util/backend";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  name: "ConstructingAsset",
  components: {
    ConstructionTable,
    Loading,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 100,
      itemData: [],
      toCancelId: null,
      constructionList: [],
      construction: {
        documentNo: "",
        name: "",
        selectExist: false,
        budget: 0,
        invoiceNo: "",
        invoiceDate: new Date(),
        vendor: "",
        list: "",
        excludeVat: 0,
        vat: 0,
        vatType: "percent",
      },
      edit: {
        constructionId: null,
        documentNo: "",
        name: "",
        budget: 0,
        invoiceNo: "",
        invoiceDate: new Date(),
        vendor: "",
        list: "",
        excludeVat: 0,
        vat: 0,
        vatType: "value",
      },
      deleteId: null,
      assetList: [],
      convert: {
        fixedAssetNo: "",
        selectExist: false,
        asset: { name: "", value: 0 },
        purchaseDate: new Date(),
        startDate: new Date(),
      },
      masks: {
        input: "DD/MM/YYYY",
      },
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
    createNet() {
      if (this.construction.vatType === "percent") {
        const net =
          this.construction.excludeVat +
          (this.construction.vat * this.construction.excludeVat) / 100;
        const convNet = Math.floor(+net * 100) / 100;
        return convNet;
      } else {
        const net = this.construction.excludeVat + this.construction.vat;
        const convNet = Math.floor(+net * 100) / 100;
        return convNet;
      }
    },
    editNet() {
      if (this.edit.vatType === "percent") {
        const net =
          this.edit.excludeVat + (this.edit.vat * this.edit.excludeVat) / 100;
        const convNet = Math.floor(+net * 100) / 100;
        return convNet;
      } else {
        const net = this.edit.excludeVat + this.edit.vat;
        const convNet = Math.floor(+net * 100) / 100;
        return convNet;
      }
    },
  },
  methods: {
    checkConstruction() {
      this.construction.name = "";
    },

    checkConvert() {
      this.convert.fixedAssetNo = "";
    },
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const filename = `${this.$t("depreciation.building_asset")}_${
          this.companyName
        }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}asset/duringConstruction/${this.company_id}/download`,
          {
            ...authHeader(),
            responseType: "blob",
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadCounstructionData();
    },

    async showCreateConstructionModal() {
      this.isLoading = true;
      try {
        const { data } = await axios.get(
          `${baseUrl()}asset/duringconstruction/dropdown/${this.company_id}`,
          authHeader()
        );
        this.constructionList = data.data;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.$bvModal.show("create-modal");
      this.isLoading = false;
    },
    hideConstructionModal() {
      this.construction = { ...DEFAULT_CONSTRUCTION };
      this.$bvModal.hide("create-modal");
    },

    async showConvertConstructionModal() {
      this.isLoading = true;
      try {
        const { data: asset } = await axios.get(
          `${baseUrl()}asset/getassetid/${this.company_id}`,
          authHeader()
        );
        this.assetList = asset.data;
        const { data } = await axios.get(
          `${baseUrl()}asset/duringconstruction/dropdown/${this.company_id}`,
          authHeader()
        );
        this.constructionList = data.data;
        this.$bvModal.show("convert-modal");
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },

    hideConvertModal() {
      this.convert = { ...DEFAULT_CONVERT };
      this.$bvModal.hide("convert-modal");
    },

    async showEditModal(value) {
      this.isLoading = true;
      try {
        const { data } = await axios.get(
          `${baseUrl()}asset/duringconstruction/dropdown/${this.company_id}`,
          authHeader()
        );
        this.constructionList = data.data;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.edit = {
        constructionId: value.id_construction,
        documentNo: value.construction_no,
        name: value.name,
        budget: value.budget,
        invoiceNo: value.invoice_no,
        invoiceDate: value.invoice_date ? new Date(value.invoice_date) : null,
        vendor: value.vendor_name,
        list: value.description,
        excludeVat: value.amount_ex_vat,
        vat: value.vat,
        vatType: "value",
      };
      this.$bvModal.show("edit-modal");
      this.isLoading = false;
    },
    hideEditModal() {
      this.edit = { ...DEFAULT_EDIT };
      this.$bvModal.hide("edit-modal");
    },

    showDeleteModal(value) {
      this.deleteId = value.id_construction;
      this.$bvModal.show("delete-modal");
    },

    async createConstruction() {
      try {
        const res = await axios.post(
          `${baseUrl()}asset/createduringconstruction/${this.company_id}`,
          {
            construction_no: this.construction.documentNo,
            name: this.construction.name,
            budget: this.construction.budget,
            invoice_no: this.construction.invoiceNo,
            invoice_date: moment(this.construction.invoiceDate)
              .startOf("d")
              .toISOString(),
            vendor_name: this.construction.vendor,
            description: this.construction.list,
            amount_ex_vat: this.construction.excludeVat,
            vat_type: this.construction.vatType,
            vat: this.construction.vat,
          },
          authHeader()
        );
        this.hideConstructionModal();
        this.loadCounstructionData();
        Swal.fire({
          icon: "success",
          text: this.$t("dialog.success"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },
    async editConstruction() {
      try {
        const res = await axios.put(
          `${baseUrl()}asset/updateduringconstruction/`,
          {
            id_company: this.company_id,
            id_construction: this.edit.constructionId,
            construction_no: this.edit.documentNo,
            name: this.edit.name,
            budget: this.edit.budget,
            invoice_no: this.edit.invoiceNo,
            invoice_date: moment(this.edit.invoiceDate)
              .startOf("d")
              .toISOString(),
            vendor_name: this.edit.vendor,
            description: this.edit.list,
            amount_ex_vat: this.edit.excludeVat,
            vat_type: this.edit.vatType,
            vat: this.edit.vat,
          },
          authHeader()
        );
        this.hideEditModal();
        this.loadCounstructionData();
        Swal.fire({
          icon: "success",
          text: this.$t("dialog.success"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },

    async deleteConstruction() {
      try {
        await axios.put(
          `${baseUrl()}asset/deleteduringconstruction/${this.company_id}`,
          {
            id_construction_list: [this.deleteId],
            note_delete: "",
          },
          authHeader()
        );
        this.deleteId = null;
        this.$bvModal.hide("delete-modal");
        this.loadCounstructionData();
        Swal.fire({
          icon: "success",
          text: this.$t("dialog.success"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },

    async createAsset() {
      this.isLoading = true;
      try {
        await axios.post(
          `${baseUrl()}asset/createAssetFromDuringConstructor/${
            this.company_id
          }`,
          {
            id_fixed_asset: this.convert.fixedAssetNo,
            name: this.convert.asset.name,
            start_date: moment(this.convert.startDate)
              .startOf("d")
              .toISOString(),
            purchase_date: moment(this.convert.purchaseDate)
              .startOf("d")
              .toISOString(),
            purchase_price: this.convert.asset.value,
          },
          authHeader()
        );
        this.hideConvertModal();
        this.isLoading = false;
        this.loadCounstructionData();
        Swal.fire({
          icon: "success",
          text: this.$t("dialog.success"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      } catch (err) {
        this.isLoading = false;
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },

    async loadCounstructionData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}asset/duringConstruction/${this.company_id}`,
          {
            params: {
              text:
                this.searchText && this.searchText !== ""
                  ? this.searchText
                  : undefined,
            },
            ...authHeader(),
          }
        );
        this.totalRows = res.data.data.count;
        this.itemData = res.data.data.rows;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadCounstructionData();
  },
};
</script>

<style lang="scss" scoped>
input:not([class^="vs__search"]),
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-icon {
  color: #007afe;
  padding: 10px 10px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  border-color: #007afe;
}

.btn-outline {
  p {
    margin: 0;
  }
  min-width: fit-content;
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.create-button {
  color: #fff;
  padding: 10px 15px;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 100%;
  max-width: 130px;
  border-radius: 10px;
  border: none;
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

.create-input {
  margin: 1rem;
  label,
  input:not([class^="vs__search"]),
  select,
  textarea {
    display: block;
    width: 100%;
  }
  label {
    color: #7c7c7c;
  }
  input:not([class^="vs__search"])[type="text"],
  .numeric-input,
  select,
  textarea {
    border-radius: 8px;
    padding: 0.4rem;
  }
  input[type="checkbox"] {
    height: 20px;
    width: 20px;
    text-align: left;
  }

  select {
    background-image: url("../../assets/down-arrow-blue.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 97% center !important;
    padding-right: 40px !important;
    border-radius: 8px;
  }

  .search-select {
    ::v-deep .vs__search::placeholder,
    ::v-deep .vs__dropdown-toggle {
      background-color: #f4f4f4;
      border: none;
      height: 45px;
    }
  }

  .calendar-input {
    cursor: pointer;
    background-image: url("../../assets/Depreciation/blue-calendar.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 97% center !important;
    padding-right: 40px !important;
    border-radius: 8px;
  }
}
</style>
